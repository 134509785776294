import {inject, Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Observable, share} from "rxjs";
import {User, UserCreate} from "../../shared/models/user";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {SectraResponse} from "../../shared/models/response";
import {Travel} from "../../shared/models/travel";
import {Alert} from "../../shared/models/alert";
import {AuthRes} from "../../shared/models/auth";
import {NotificationSettings, UsersListFilter} from "./users.models";
import {Notification} from "../../shared/models/utils";
import {EnumRoles} from "../../core/auth/models/roles.model";
import {ErrorService} from "../../core/services/error.service";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly basePath = `${environment.API_BASEURL}/${environment.API_VERSION}`;
  private readonly rolePath = 'common';

  // services
  private readonly http = inject(HttpClient);
  private readonly toastService = inject(ToastrService);
  private readonly errorService = inject(ErrorService);

  getAll(filters: UsersListFilter): Observable<SectraResponse<User[]>> {
    const {searchQuery, companyId, roleId, errors, sort, page, limit} = filters;
    const req$ = this.http.get<SectraResponse<User[]>>(`${this.basePath}/${this.rolePath}/users/${EnumRoles.All}/${page}/${limit}/${sort}/${searchQuery ? '&search=' + searchQuery : ''}${companyId ? '&companyId=' + companyId : ''}${roleId && roleId !== '*' ? '&roleId=' + roleId : ''}${errors ? '&disabled=true' : ''}`)
      .pipe(share());
    req$.subscribe({
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  getById(id: number): Observable<SectraResponse<User>> {
    const req$: Observable<SectraResponse<User>> = this.http.get<SectraResponse<User>>(`${this.basePath}/${this.rolePath}/users/${id}`).pipe(share());
    req$.subscribe({
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  createOne(user: UserCreate): Observable<SectraResponse<User>> {
    const req$: Observable<SectraResponse<User>> = this.http.post<SectraResponse<User>>(`${this.basePath}/${this.rolePath}/users`, user).pipe(share());
    req$.subscribe({
      next: (res: SectraResponse<User>) => {
        this.toastService.success('User added');
      },
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  updateOne(id: number, user: UserCreate): Observable<SectraResponse<User>> {
    const req$: Observable<SectraResponse<User>> = this.http.patch<SectraResponse<User>>(`${this.basePath}/${this.rolePath}/users/${id}`, user).pipe(share());
    req$.subscribe({
      next: (res: SectraResponse<User>) => {
        this.toastService.success('User updated');
      },
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  deleteOne(id: number): Observable<SectraResponse<void>> {
    const req$: Observable<SectraResponse<void>> = this.http.delete<SectraResponse<void>>(`${this.basePath}/${this.rolePath}/users/${id}`).pipe(share());
    req$.subscribe({
      next: (res: SectraResponse<void>) => {
        this.toastService.success('User deleted');
      },
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  // ME
  getPersonalData(): Observable<SectraResponse<User>> {
    const req$: Observable<SectraResponse<User>> = this.http.get<SectraResponse<User>>(`${this.basePath}/${this.rolePath}/users/me`).pipe(share());
    req$.subscribe({
      next: (res: SectraResponse<User>) => {
        // this.selectedUser$.next(res.data);
      },
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  getPersonalTravels(type: string, page: number, limit: number, sort: string, searchQuery?: string): Observable<SectraResponse<Travel[]>> {
    const req$: Observable<SectraResponse<Travel[]>> = this.http.get<SectraResponse<Travel[]>>(`${this.basePath}/${this.rolePath}/users/me/travels/${type}/${page}/${limit}/${sort}/${searchQuery ? searchQuery : ''}`).pipe(share());
    req$.subscribe({
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  getTravels(id: number, type: string, page: number, limit: number, sort: string, searchQuery?: string): Observable<SectraResponse<Travel[]>> {
    const req$: Observable<SectraResponse<Travel[]>> = this.http.get<SectraResponse<Travel[]>>(`${this.basePath}/${this.rolePath}/users/${id}/travels/${type}/${page}/${limit}/${sort}/${searchQuery ? searchQuery : ''}`).pipe(share());
    req$.subscribe({
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  getPersonalAlerts(type: string, page: number, limit: number, sort: string, searchQuery?: string): Observable<SectraResponse<Alert[]>> {
    const req$: Observable<SectraResponse<Alert[]>> = this.http.get<SectraResponse<Alert[]>>(`${this.basePath}/${this.rolePath}/users/me/alerts/${type}/${page}/${limit}/${sort}/${searchQuery ? searchQuery : ''}`).pipe(share());
    req$.subscribe({
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  getAlerts(id: number, type: string, page: number, limit: number, sort: string, searchQuery?: string): Observable<SectraResponse<Alert[]>> {
    const req$: Observable<SectraResponse<Alert[]>> = this.http.get<SectraResponse<Alert[]>>(`${this.basePath}/${this.rolePath}/users/${id}/alerts/${type}/${page}/${limit}/${sort}/${searchQuery ? searchQuery : ''}`).pipe(share());
    req$.subscribe({
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  changeUserRole(id: number | string, roleId: string): Observable<SectraResponse<void>> {
    const req$ = this.http.patch<SectraResponse<void>>(`${this.basePath}/${this.rolePath}/users/${id}/change-role/${roleId}`, {}).pipe(share());
    req$.subscribe({
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  switchCompanyAdminToTraveller(): Observable<SectraResponse<AuthRes>> {
    const req$ = this.http.patch<SectraResponse<AuthRes>>(`${this.basePath}/${this.rolePath}/users/me/switch-mode`, {}).pipe(share());
    req$.subscribe({
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  updateNotificationSettings(data: NotificationSettings): Observable<SectraResponse<User>> {
    const req$ = this.http.patch<SectraResponse<User>>(`${this.basePath}/${this.rolePath}/users/me/settings`, data).pipe(share());
    req$.subscribe({
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  updatePassword(data: { oldPassword: string; password: string }): Observable<SectraResponse<void>> {
    const req$ = this.http.post<SectraResponse<void>>(`${this.basePath}/${this.rolePath}/users/me/password`, data).pipe(share());
    req$.subscribe({
      next: (res: SectraResponse<void>) => {
        this.toastService.success('Password updated successfully');
      },
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

  // NOTIFICATIONS

  getNotifications(page: number): Observable<SectraResponse<Notification[]>> {
    return this.http.get<SectraResponse<Notification[]>>(`${this.basePath}/${this.rolePath}/users/me/notifications/${page}`);
  }

  uploadNotificationReadStatus(): Observable<SectraResponse<void>> {
    return this.http.patch<SectraResponse<void>>(`${this.basePath}/${this.rolePath}/users/me/notifications`, {});
  }

  // LANGUAGE
  setUserLanguage(language: string): Observable<SectraResponse<void>> {
    return this.http.patch<SectraResponse<void>>(`${this.basePath}/${this.rolePath}/users/me/language`, {language});
  }

  setIsUserDisabled(id: number, disabled: boolean): Observable<SectraResponse<User>> {
    const req$ = this.http.patch<SectraResponse<User>>(`${this.basePath}/admin/users/${id}/disable`, {disabled})
      .pipe(share());
    req$.subscribe({
      next: () => {
        this.toastService.success('User disabled');
      },
      error: (err) => {
        this.errorService.showErrors(err);
      }
    });
    return req$;
  }

}
